<template>
  <div
    id="wrapper-mobile-size"
  >
    <div class="mobile-size">
      <div v-if="resultOfVotes.fetching === 'rejected'">
        <div
          class="card mt-2 text-center"
        >
          <div class="card-body">
            <p> {{ resultOfVotes.data.message }} </p>
          </div>
        </div>
      </div>
      <div
        v-else-if="resultOfVotes.fetching === 'resolved'"
        id="wrapper-content"
        class="d-flex flex-column"
      >
        <div
          class="d-flex flex-column"
          :style="`background-image: url('${bgRekapitulasiTotalVote}')`"
        >
          <div class="wrapper-last-update">
            <p
              id="lates-update"
              class="badge badge-pill badge-light text-black text-dark"
            >
              diupdate terakhir <span class="text-primary">
                <!-- 20 Sep 2021 15:02 WIB -->
                {{ new Date(resultOfVotes.data.timestamp).toLocaleString('id-ID', { timeZone: 'Asia/Jakarta' }) }} WIB
              </span>
            </p>
          </div>
          <div
            class="wrapper-total-vote-masuk"
          >
            <div class="total-vote-masuk">
              <img :src="require('@/assets/images/pages/tps-logo.png')">
              <div class="info">
                <div class="total">
                  {{ total }}
                </div>
                <div class="total-masuk">
                  Total Vote Masuk
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <CartRecapulation :result-of-votes="resultOfVotes.data.results" />
          </div>
        </div>
        <div class="footer-divider" />
        <div
          v-if="resultOfVotes.data.is_final === 1 && !isSeimbang && resultOfVotes.data.current_winner"
          class="wrapper-button-pemenang"
        >
          <div>
            <button
              v-b-modal.modal-1
              class="btn btn-primary btn-block"
            >
              Lihat Pemenang
            </button>
          </div>
        </div>
        <b-modal
          v-if="resultOfVotes.data.is_final === 1 && !isSeimbang && resultOfVotes.data.current_winner"
          id="modal-1"
          ref="modal-winner"
          hide-footer
          centered
          size="sm"
          hide-header
          hide-header-close
        >
          <div class="wrapper-button-cancel">
            <div @click="$bvModal.hide('modal-1')">
              <feather-icon
                icon="XIcon"
                size="32"
              />
            </div>
          </div>
          <div class="text-center">
            <div
              id="image-winner"
              class="mb-2"
              :style="`background-image:  url('${resultOfVotes.data.current_winner ? resultOfVotes.data.current_winner.candidate.photo : ''}pemenang.png'), url('${bgWinner}')`"
            />
            <h3
              class="text-uppercase"
              style="color: #F4821F;"
            >
              {{ resultOfVotes.data.current_winner ? resultOfVotes.data.current_winner.candidate.nama : '' }}
            </h3>
            <p>Sebagai <br>

              <b>Ketua Umum Ikatan Alumni</b>
              <br>
              Fakultas Ilmu Sosial dan Ilmu Politik Universitas Indonesia
              <br>
              Periode 2022-2025</p>
          </div>
          <div
            id="footer"
            class="mb-2"
          >
            <img
              :src="logoIluniUi"
              alt="logo_iluniui"
              class="logo-footer iluni"
            >
            <img
              :src="logoUiConnect"
              alt="logo_uiconnect_lowres"
              class="logo-footer uiconnect"
            >
            <img
              :src="logoIluniFhhi"
              alt="logo_ilunuifhui"
              class="logo-footer fisip"
            >
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import voteIcon from '@/assets/images/icons/vote-icon.svg'
import CartRecapulation from '@/components/CartRecapulation/CartRecapulation.vue'
import bgWinner from '@/assets/images/pages/bg-winner.svg'
import userWinner from '@/assets/images/pages/user-winner.svg'
import logoIluniUi from '@/assets/images/logo/iluni.png'
import logoIluniFhhi from '@/assets/images/logo/fisip.svg'
import logoUiConnect from '@/assets/images/logo/uiconnect.png'
import bgRekapitulasiTotalVote from '@/assets/images/pages/bg-total-rekapitulasi.png'

import API from '@/api'

export default {
  components: {
    CartRecapulation,
    BModal,
  },
  data() {
    return {
      voteIcon,
      bgWinner,
      userWinner,
      logoIluniUi,
      logoIluniFhhi,
      logoUiConnect,
      bgRekapitulasiTotalVote,
      resultOfVotes: {
        fetching: 'idle',
        data: null,
      },
    }
  },
  computed: {
    isSeimbang() {
      const dict = {}
      const { results } = this.resultOfVotes.data
      for (let i = 0; i < results.length; i += 1) {
        const element = results[i].result?.count || 0
        if (!(element in dict)) {
          dict[element] = 0
        }
        dict[element] += 1
      }

      return Object.keys(dict).length === 1
    },
    total() {
      if (!this.resultOfVotes?.data?.total_vote) {
        return 0
      }
      return this.resultOfVotes.data.total_vote
    },
  },
  created() {
    this.fetchResultOfVotes()
  },
  methods: {
    async fetchResultOfVotes() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        this.resultOfVotes.fetching = 'pending'
        const { data } = await API.election.resultVote()
        this.resultOfVotes.data = data
        this.resultOfVotes.fetching = 'resolved'

        setTimeout(() => {
          if (this.resultOfVotes.data.is_final === 1 && !this.isSeimbang) {
            this.$refs['modal-winner'].show()
          }
        }, 100)
      } catch (error) {
        console.log(error)
        this.resultOfVotes.data = error.response.data
        this.resultOfVotes.fetching = 'rejected'
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
  },
}
</script>

<style scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }

    .wrapper-button-pemenang {
      width: 100vw !important;
    }
  }

  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
  }

  #wrapper-content {
    width: 100%;
  }

  #title {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: bold;
    margin: auto 0;
  }

  .title-2 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    margin: auto 0;
  }

  #lates-update {
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin: auto 0;
    border: 1px solid #48CFB2;
    background: #FFFFFF;
  }

  #image-winner {
    height: 14.122rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 154px 156px, contain;
  }
  #footer {
   height: 4.75rem;
   line-height: 4.75rem;
   background-color: #ffffff;
   display: flex;
   justify-content: center;
   align-items: center;
 }

  .logo-footer {
    height: 4.25rem;
  }

  /* @media (min-device-width: 1200px) {
    #wrapper-content {
      width: 80%;
    }
  } */

  .wrapper-last-update {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    padding-top: 16px;
    border-radius: 20px;
  }

  .wrapper-total-vote-masuk {
    padding: 12px
  }

  .total-vote-masuk {
    width: 100%;
    height: 128px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 25px;
  }

  .total-vote-masuk img {
    width: 80px;
    height: 80px;
    background: linear-gradient(148.17deg, #E2ECFB 10.59%, #F9FAFF 88.05%);
    border-radius: 50%;
  }

  .total-vote-masuk .info {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .total-vote-masuk .info .total {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color:  #4FBBEA;
  }

   .total-vote-masuk .info .total-masuk {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* text primary */

    color: #1B1B1B;
   }

   .logo-footer {
    height: 41px;
    margin: 0 5px;
  }

  .uiconnect {
    width: 54px;
    height: 35px;
  }

  .iluni {
    width: 30.7px;
    height: 39.51px;
  }

  .wrapper-button-cancel {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;
  }
  .wrapper-button-cancel div {
    cursor: pointer;
    color: #1B1B1B;
  }

  .wrapper-button-pemenang {
    position: absolute;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(224, 224, 224, 0.8);
    height: 70px;
    width: 360px;
    padding: 0 32px;
  }

  .wrapper-button-pemenang div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .wrapper-button-pemenang button {
    background: linear-gradient(100.3deg, #48CFB2 7.79%, #4FBBEA 95.2%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 35px;
    color: #FFFFFF;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  .footer-divider {
    margin-bottom: 70px;
  }

</style>
